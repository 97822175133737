import { useState, useEffect } from 'react';
import { chainId, abbreviateAddress } from '../utils/EtherUtils';
import { BrowserView, MobileView } from 'react-device-detect';

export default function WalletView({ showModal, wallet, onWalletConnect }) {


    const [address, setAddress] = useState(null)
    const [isConnected, setIsConnected] = useState(false)
    const [walletDisabled, setWalletDisabled] = useState(true)


    const initWallet = async () => {
        const isConnected = await wallet.checkWalletConnection()
        if (isConnected) {
            await connectWallet()
        }
        await getAddress()

        setIsConnected(isConnected)
    }

    const connectWallet = async () => {
        try {
            await wallet.connect()
            setIsConnected(true)
            onWalletConnect(wallet)
            await getAddress()
        }
        catch (err) {
            showModal(err.message, true)
        }
    }

    const handleConnect = async () => {
        try {
            await wallet.connect()
            await getAddress()
            await checkNetwork(chainId)

            setIsConnected(true)
            onWalletConnect(wallet)
        }
        catch (err) {
            showModal(err.message, true)
        }
    }

    const getAddress = async () => {
        const addr = await wallet.getWalletAddress()
        if (addr) {
            setAddress(abbreviateAddress(addr));
        }
    }


    useEffect(() => {
        if (!wallet) setWalletDisabled(true)
        else {
            setWalletDisabled(false)
            initWallet()
                .catch(err => {

                })
        }
    }, [wallet])


    const checkNetwork = async (chainId) => {
        const isCorrectNetwork = await wallet.checkNetwork(chainId)
        if (!isCorrectNetwork) {
            await switchNetwork(chainId)
        }
    }

    const switchNetwork = async (chainId) => {
        await wallet.addNetwork(chainId)
        await wallet.changeNetwork(chainId)
    }

    const hasEthereum = !!window.ethereum


    return (
        <div className='wallet'>
            <div className='conntectWallet'>
                <BrowserView>
                    <button disabled={walletDisabled} type="button" onClick={handleConnect} className={isConnected ? 'btnType01 connect' : 'btnType01'}>{isConnected ? address : 'connect walLet'}</button>
                </BrowserView>
                <MobileView>
                    {
                        hasEthereum
                            ? <button disabled={walletDisabled} type="button" onClick={handleConnect} className={isConnected ? 'btnType01 connect' : 'btnType01'}>{isConnected ? address : 'connect walLet'}</button>
                            : <a href="https://metamask.app.link/dapp/dev.ofotd.com" className='btnType01'>connect wallet</a>
                    }
                </MobileView>
            </div>
        </div>
    )
}