import { useEffect, useState } from "react"


export default function MinterView({ contract, showModal }) {

    const [remaining, setRemaining] = useState('-')
    const [salesStart, setSalesStart] = useState(null)
    const [mintDisabled, setMintDisabled] = useState(true)
    const [price, setPrice] = useState('235')
    const [amount, setAmount] = useState(1);
    const [maxPerTx, setMaxPerTx] = useState(1);

    useEffect(() => {
        if (contract) {
            contract
                .mintInfo()
                .then(mintInfo => {
                    setRemaining(mintInfo.maxPublicSupply - mintInfo.mintedAmount)
                    setPrice(mintInfo.price)
                    setMaxPerTx(mintInfo.maxPerTx)
                    setSalesStart(mintInfo.salesStart)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [contract])

    useEffect(() => {
        let interval = null
        if (!!salesStart) {
            interval = setInterval(() => {
                setMintDisabled(salesStart.getTime() > new Date().getTime())
            }, 500)
        }
        return () => clearInterval(interval);

    }, [salesStart])

    const mint = () => {

        if (!contract) { showModal('Wallet is not installed', true) }
        else {
            contract
                .mint(amount, price)
                .then(() => {
                    showModal('MINT SUCCESS', true)
                })
                .catch(err => {
                    showModal(err.code ? 'FAILED' : err.message, true)
                })
        }
    }

    function decrementAmount() {
        if (amount > 1) {
            setAmount(amount - 1)
        }
    }

    function incrementAmount() {
        if (amount < maxPerTx) {
            setAmount(amount + 1)
        }
    }

    return (
        <div className="minTingAction">
            <div className="minTingBox">
                <strong>PUBLIC MINT</strong>
                <div className="minTingApp">
                    <dl>
                        <dt>Remaining</dt>
                        <dd>{remaining}</dd>
                    </dl>
                    <dl>
                        <dt>PRICE</dt>
                        <dd><span>{price}</span>&nbsp;MATIC / PER</dd>
                    </dl>
                    <dl>
                        <dt>AMOUNT</dt>
                        <dd className="amounDd">
                            <button type="button" className="btnAmount minus" onClick={decrementAmount}>-</button>
                            <button className="btnAmount plus" onClick={incrementAmount}>+</button>
                            <input type="text" id="amount" value={amount} />
                        </dd>
                    </dl>
                </div>
            </div>
            {
                remaining === '-'
                && <div className="remain">*Connect wallet to check the remaining</div>
            }
            
            {
                remaining != '0'
                ? <button type="button" className="btnMinting" onClick={mint} disabled={mintDisabled}>START MINTING</button>
                : <button type="button" className="btnMinting" onClick={mint} disabled>SOLD OUT</button>
            }
        </div>
    )
}
