// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

// Vercel Analytics
import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

ReactDOM.createRoot(
      document.getElementById('root')
).render(
  <App />
);

reportWebVitals(sendToVercelAnalytics);
