import Web3Modal from 'web3modal'
import { providers, utils } from "ethers";
import { NETWORKS } from '../constants/Networks';

const SWITCH_CHAIN_REQUEST = 'wallet_switchEthereumChain'
const ADD_CHAIN_REQUEST = 'wallet_addEthereumChain'
const GET_BALANCE = 'eth_getBalance'
const GET_ACCOUNT = 'eth_accounts'
const CHAIN_ID = parseInt(process.env.REACT_APP_CHAIN_ID)

class Wallet {

    _throwIfNotConnected() {
        if (!this.provider) throw Error('Wallet not connected yet')
    }

    constructor() {

        if (!window.ethereum) {
            throw Error('YOU MUST INSTALL A CRYPTO WALLET.')
        }

        this.web3Modal = new Web3Modal({
            network: NETWORKS[CHAIN_ID],
            providerOptions: {},
            cacheProvider: true,
            disableInjectedProvider: false,
        })

    }

    async checkWalletConnection() {
        const accounts = await window.ethereum?.request({ method: GET_ACCOUNT })
        return accounts.length > 0
    }

    async connect() {
        const connection = await this.web3Modal.connect()
        this.provider = new providers.Web3Provider(connection, 'any')
        this.provider.on('newtork', (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload();
            }
        })


    }

    async getWalletAddress() {
        const accounts = await window.ethereum?.request({ method: GET_ACCOUNT })
        return accounts.length > 0 ? accounts[0] : null
    }

    async checkNetwork() {
        this._throwIfNotConnected()

        const network = await this.provider.detectNetwork()
        return network.chainId === CHAIN_ID
    }

    async changeNetwork() {
        this._throwIfNotConnected()

        await this.provider.send(SWITCH_CHAIN_REQUEST, [{ chainId: NETWORKS[CHAIN_ID].chainId }])

    }

    async addNetwork() {
        this._throwIfNotConnected()

        await this.provider.send(ADD_CHAIN_REQUEST, [NETWORKS[CHAIN_ID]])
    }

    async getUserBalance(fixedPoint = 5) {
        this._throwIfNotConnected()

        const balance = await this.provider
            .send(GET_BALANCE, [window.ethereum.selectedAddress])
        return parseFloat(utils.formatEther(balance))
            .toFixed(fixedPoint)
    }

}

export default Wallet