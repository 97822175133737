import './App.css';
import Mint from './view/Mint';
function App() {

  return (
    <div id="wrap">
      <header className="header">
        <div className="head">
          <div className="logo">
            <img src={process.env.PUBLIC_URL + '/images/offo-title.png'} alt="OFOTD FOUNDATION: UNIFORM 0.1" />
          </div>
          <div className='creator-txt'>
            <img src={process.env.PUBLIC_URL + '/images/img-creator-text.png'} alt="CREATOR - lesugiatelier & reikwon, TITLE - OFOTD FOUNDATION: UNIFORM 0.1" />
          </div>
          <div className='creator-txt2'>
            <img src={process.env.PUBLIC_URL + '/images/img-creator-text2.png'} alt="CREATOR - lesugiatelier & reikwon, TITLE - OFOTD FOUNDATION: UNIFORM 0.1" />
          </div>
        </div>
      </header>
      <main>
        <Mint />
      </main>
    </div>
  );
}

export default App;