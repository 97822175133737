import CONTRACT from "../constants/Contract"
import { providers, Contract } from "ethers"
import { chainId, decimalToEther, etherToDecimal } from './EtherUtils'
class Minter {

    constructor() {

        if (!window.ethereum) throw new Error('YOU MUST INSTALL A CRYPTO WALLET.')

        const provider = new providers.Web3Provider(window.ethereum, 'any')
        provider.on('network', (newNetwork, oldNetwork) => {
            if (oldNetwork) {
                window.location.reload();
            }
        });

        this.readonlyContract = new Contract(
            CONTRACT.address,
            CONTRACT.abi,
            provider
        )

        this.readonlyProvider = provider


    }


    setSigner(_signer) {
        if (!_signer) {
            throw Error('Wallet Not Connected')
        }
        this.provider = _signer
        this.contract = new Contract(
            CONTRACT.address,
            CONTRACT.abi,
            this.provider.getSigner())

    }

    _checkSigner() {
        if (!this.provider) {
            throw Error('Wallet Not Connected')
        }
    }

    async _checkChain() {
        this._checkSigner()
        const currentChain = await this.provider.detectNetwork()
        if (chainId !== currentChain.chainId) {
            throw Error(`must connected to chain ${chainId}`)
        }
    }

    async mint(qty = 1, price = 0.001) {
        await this._checkChain()
        await this.contract.mint(qty, { value: decimalToEther(price * qty) })
    }

    async nftBalance() {
        await this._checkChain()
        const balance = await this.contract.balanceOf(window.ethereum.selectedAddress)
        return balance.toNumber()
    }

    async mintInfo() {
        const current = await this.readonlyProvider.detectNetwork()
        if (current.chainId !== chainId) {
            throw new Error('Please connect your wallet to Polygon Chain')
        }
        const [
            price,
            maxPublicSupply,
            mintedAmount,
            maxPerWallet,
            maxPerTx,
            blockInterval,
            salesStart
        ] = await this.readonlyContract.getMintInfo()
        const mintInfo = {
            price: etherToDecimal(price),
            maxPublicSupply: maxPublicSupply.toNumber(),
            mintedAmount: mintedAmount.toNumber(),
            maxPerWallet: maxPerWallet.toNumber(),
            maxPerTx: maxPerTx.toNumber(),
            blockInterval: blockInterval.toNumber(),
            salesStart: new Date(salesStart * 1000),
        }
        return mintInfo
    }


}

export default Minter