import WalletView from '../components/WalletView'
import Wallet from '../utils/Wallet'
import MinterView from '../components/MinterView'
import Minter from '../utils/Minter'
import ModalPortal from '../components/ModalPortal'
import { useState } from 'react'

export default function Mint() {


    const [modalOpened, setModalOpened] = useState(false);
    const [popup, setPopup] = useState(false);


    const initWallet = () => {
        try {
            return new Wallet()
        } catch (err) {
            return null
        }
    }

    const initMinter = () => {
        try {
            return new Minter()
        } catch (err) {
            return null
        }
    }

    const wallet = initWallet()
    const contract = initMinter()

    const walletConnects = async (walletConnected) => {
        contract.setSigner(walletConnected.provider)
    }

    const handleOpen = (message, isOpen = true) => {
        setPopup(message)
        setModalOpened(isOpen)
    };

    const handleClose = () => handleOpen(null, false)





    return (
        <div className='minterView'>
            <WalletView showModal={handleOpen} wallet={wallet} onWalletConnect={walletConnects} />
            <MinterView showModal={handleOpen} contract={contract} />
            {modalOpened && (
                <ModalPortal closePortal={handleClose}>
                    {popup}
                </ModalPortal>
            )}
        </div>
    )
}