export const NETWORKS = {
    80001: {
        chainId: '0x13881',
        rpcUrls: ['https://rpc.ankr.com/polygon_mumbai'],
        chainName: 'Polygon Mumbai',
        nativeCurrency: { name: 'Mumbai-Matic', decimals: 18, symbol: 'MATIC' },
        blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
        iconUrls: []
    },
    137: {
        chainId: '0x89',
        rpcUrls: ['https://polygon-rpc.com/'],
        chainName: 'Polygon Mainnet',
        nativeCurrency: { name: 'Matic', decimals: 18, symbol: 'MATIC' },
        blockExplorerUrls: ['https://polygonscan.com'],
        iconUrls: []
    }
}