import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

export default function ModalPortal({ children, closePortal }) {
  const [mounted, setMounted] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    setMounted(true);
    if (document) {
      const dom = document.getElementById("root-modal");
      ref.current = dom;
    }
    return () => {
      setMounted(false);
    };
  }, []);

  if (ref.current && mounted) {
    return createPortal(
      <div className="modal">
        <div
          className="modal-background"
          role="presentation"
          onClick={closePortal}
        />
        <div className="modal-content">
          <div className="modal-content__close">
            <button type="button" onClick={closePortal}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35" fill="#000">
                <g clipPath="url(#clip0_200_32787)">
                  <path d="M0.359375 34.3501L34.3594 0.350098" stroke="black" strokeMiterlimit="10"></path>
                  <path d="M34.3594 34.3501L0.359375 0.350098" stroke="black" strokeMiterlimit="10"></path>
                </g>
                <defs>
                  <clipPath id="clip0_200_32787">
                    <rect width="34.71" height="34.71" fill="white" transform="translate(0.0078125)"></rect>
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
          <div className="modal-content__main">{children}</div>
        </div>
      </div>,
      ref.current
    );
  }
  return null;
};

